
$included-layouts: halves;//, thirds, quarters;

.grid {
   display: flex;
   flex-wrap: wrap;
   padding-left: 0;
   padding-right: 0;
   margin: 0 -#{$grid-spacing} 1em;

   &__item {
      flex-grow: 0;
      padding: $grid-spacing;

      &.-fl {
         display: flex;
         flex-direction: column;

         > :only-child {
            flex-grow: 1;
         }
      }
   }

   @if index($included-layouts, halves) != null {
      &.-halves {
         .grid__item {
            @include grid(650px 2);
         }

         @include min(#{$max-width}) {
            margin-left: -$grid-spacing-fixed;
            margin-right: -$grid-spacing-fixed;

            .grid__item {
               padding: $grid-spacing-fixed;
            }
         }

      }
   }

   @if index($included-layouts, thirds) != null {
      &.-thirds {
         .grid__item {
            @include grid(600px 2, 800px 3);
         }

         @include min(800px) {
            margin-left: -$grid-spacing*.75;
            margin-right: -$grid-spacing*.75;

            .grid__item {
               padding: $grid-spacing*.75;
            }
         }

         @include min(#{$max-width}) {
            margin-left: -$grid-spacing-fixed*.75;
            margin-right: -$grid-spacing-fixed*.75;

            .grid__item {
               padding: $grid-spacing-fixed*.75;
            }
         }
      }
   }

   @if index($included-layouts, quarters) != null {
      &.-quarters {
         .grid__item {
            @include grid(600px 2, 800px 3, 1400px 4);
         }

         @include min(800px) {
            margin-left: -$grid-spacing*.75;
            margin-right: -$grid-spacing*.75;

            .grid__item {
               padding: $grid-spacing*.75;
            }
         }

         @include min(1400px) {
            margin-left: -$grid-spacing*.5;
            margin-right: -$grid-spacing*.5;

            .grid__item {
               padding: $grid-spacing*.5;
            }
         }

         @include min(#{$max-width}) {
            margin-left: -$grid-spacing-fixed*.5;
            margin-right: -$grid-spacing-fixed*.5;

            .grid__item {
               padding: $grid-spacing-fixed*.5;
            }
         }
      }
   }
}


.agnostic-grid {
   margin: 0 0 3em;
   padding: 0;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
   grid-gap: 4vmax;
   color: hsl(17, 6%, 20%);
}