
.contact {
	padding: 3rem 0;
	margin-bottom: 2rem;
	background-color: $color-brand;
	color: #ffffff;

	&__content {
		@include min(700px) {
			display: flex;
			justify-content: space-between;
		}
	}

	&__info,
	&__form {
		flex-basis: 50%;
	}

	&__info {
		margin-bottom: 3rem;

		@include min(700px) {
			margin-bottom: 0;
			margin-right: 3rem;
		}
	}

	&__heading {
		font-size: rem(30px);
		color: $color-secondary;
		font-weight: bold;
		margin-bottom: 1.1em;
	}
}