
.main-nav {
	padding: 1em 0;

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		list-style-type: none;

		@include min(600px) {
			flex-direction: row;
		}
	}

	&__item {
		display: inline-block;
		font-size: rem(22px);
		margin: 10px 2vw;
		text-align: center;
		font-weight: normal;
	}

	&__link {
		padding-bottom: 3px;

		@include link(&) {
			text-decoration: none;
			color: #313131;
			border-bottom: 3px transparent solid;
		}

		@include link-over(&) {
			border-bottom-color: $color-secondary;
		}
	}
}
