/*
* BASE DEPENDENCIES
*/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*
* SHARED
*/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}

@keyframes slide-from-top {
	from {
		transform: translateY(-100%);
	} to {
		transform: translateY(0);
	}
}
*/
body {
  color: #383433;
  font-family: Roboto, Nunito;
  text-decoration-skip-ink: auto;
  font-variant: normal;
}

::selection {
  background: #ffd700;
  color: #383433;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: #002c45;
}

a:hover,
a:focus,
a:active {
  text-decoration-color: #ffd700;
}

p a:visited {
  opacity: .8;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1.61803em;
}

h1:not(:first-child), h2:not(:first-child), h3:not(:first-child), h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
  margin-top: 0;
}

h1, h2, h3, h4 {
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 .75em 0;
}

h5, h6 {
  font-weight: 600;
  margin: 0 0 .5em 0;
}

.main-heading {
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.875rem;
  font-weight: normal;
}

h4 {
  font-size: 1.625rem;
}

h5 {
  font-size: 1.375rem;
}

h6 {
  font-size: 1.125rem;
}

:focus {
  outline: 0;
}

blockquote {
  padding: 4vw;
  margin: 5vw;
  border-left: 1em #002c45 solid;
  font-size: 1.6em;
  font-style: normal;
  /* cite {
		font-style: normal;
	} */
}

blockquote p {
  margin: 0;
  position: relative;
}

@media screen and (min-width: 800px) {
  blockquote {
    margin: 4vw;
  }
}

@media screen and (min-width: 1200px) {
  blockquote {
    margin: 3em 5em;
    padding-left: 4em;
    padding-right: 4em;
  }
}

code,
pre {
  font-size: 1em;
  font-family: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

.button-link-wrapper {
  margin-top: 2em;
}

.button-link {
  padding: .8em 2em;
  margin: 0;
  display: inline-block;
  min-width: 150px;
  text-align: center;
}

.button-link,
.button-link:link,
.button-link:visited {
  background-color: #ffd700;
  color: #002c45;
  text-decoration: none;
}

.button-link:hover,
.button-link:focus,
.button-link:active {
  background-color: #002c45;
  color: #ffd700;
}

/*
* COMMON BLOCKS
*/
.keyboard-focus:focus {
  outline: 3px skyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
.header {
  background: #ffffff none;
  color: #f6f2ef;
}

.motto {
  color: #ffd700;
  font-size: 1.2rem;
  margin: 0 0 2em;
  text-align: center;
}

.logo {
  margin: 2rem 0 1rem;
}

@media screen and (min-width: 700px) {
  .logo {
    margin-right: 3rem;
    margin-bottom: 2rem;
  }
}

.footer {
  text-align: center;
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #ffd700;
  color: #383433;
  margin-top: auto;
}

/*
* DESIGN COMPONENTS
*/
.contact {
  padding: 3rem 0;
  margin-bottom: 2rem;
  background-color: #002c45;
  color: #ffffff;
}

@media screen and (min-width: 700px) {
  .contact__content {
    display: flex;
    justify-content: space-between;
  }
}

.contact__info, .contact__form {
  flex-basis: 50%;
}

.contact__info {
  margin-bottom: 3rem;
}

@media screen and (min-width: 700px) {
  .contact__info {
    margin-bottom: 0;
    margin-right: 3rem;
  }
}

.contact__heading {
  font-size: 1.875rem;
  color: #ffd700;
  font-weight: bold;
  margin-bottom: 1.1em;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0 -2vmax 1em;
}

.grid__item {
  flex-grow: 0;
  padding: 2vmax;
}

.grid__item.-fl {
  display: flex;
  flex-direction: column;
}

.grid__item.-fl > :only-child {
  flex-grow: 1;
}

@media screen and (max-width: 650px) {
  .grid.-halves .grid__item {
    width: 100%;
  }
}

@media screen and (min-width: 649px) {
  .grid.-halves .grid__item {
    width: 50%;
  }
}

@media screen and (min-width: 1600px) {
  .grid.-halves {
    margin-left: -30px;
    margin-right: -30px;
  }
  .grid.-halves .grid__item {
    padding: 30px;
  }
}

.agnostic-grid {
  margin: 0 0 3em;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 4vmax;
  color: #363230;
}

.main-nav {
  padding: 1em 0;
}

.main-nav__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media screen and (min-width: 600px) {
  .main-nav__list {
    flex-direction: row;
  }
}

.main-nav__item {
  display: inline-block;
  font-size: 1.375rem;
  margin: 10px 2vw;
  text-align: center;
  font-weight: normal;
}

.main-nav__link {
  padding-bottom: 3px;
}

.main-nav__link, .main-nav__link:link, .main-nav__link:visited {
  text-decoration: none;
  color: #313131;
  border-bottom: 3px transparent solid;
}

.main-nav__link:hover, .main-nav__link:focus, .main-nav__link:active {
  border-bottom-color: #ffd700;
}
