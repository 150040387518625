
.header {
	background: #ffffff none;
	color: $color-light;
}

.motto {
	color: $color-secondary;
	font-size: 1.2rem;
	margin: 0 0 2em;
	text-align: center;
}

.logo {
	margin: 2rem 0 1rem;

	@include min(700px) {
		margin-right: 3rem;
		margin-bottom: 2rem;
	}
}