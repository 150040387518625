
body {
	color: #383433;
	font-family: $font-body;
	text-decoration-skip-ink: auto;
	font-variant: normal;
}

::selection {
	background: $color-secondary;
	color: $color-dark;
}

@include link {
	color: inherit;
	text-decoration: underline;
	text-decoration-color: $color-brand;
}

@include link-over {
	//color: $color-brand;
	text-decoration-color: $color-secondary;
}

@include link-visited("p a") {
	opacity: .8;
}

@include headings(1, 6) {
	margin-top: to-length(const("GOLDEN_RATIO"), "em");

	&:not(:first-child) {
		margin-top: 0;
	}
}

@include headings(1, 4) {
	font-weight: 700;
	line-height: 1.15;
	margin: 0 0 .75em 0;
}

@include headings(5, 6) {
	font-weight: 600;
	margin: 0 0 .5em 0;
}

.main-heading {
	font-size: 3rem;
	font-weight: bold;
}

h2 {
	font-size: 2.25rem;
}

h3 {
	font-size: 1.875rem;
	font-weight: normal;
}

h4 {
	font-size: 1.625rem;
}

h5 {
	font-size: 1.375rem;
}

h6 {
	font-size: 1.125rem;
}

:focus {
	outline: 0;
}

.keyboard-focus:focus {
	@extend %default-outline;
}

blockquote {
	padding: 4vw;
	margin: 5vw;
	border-left: 1em $color-brand solid;
	font-size: 1.6em;
	font-style: normal;

	/* cite {
		font-style: normal;
	} */

	p {
		margin: 0;
		position: relative;
	}

	@include min(800px) {
		margin: 4vw;
	}

	@include min(1200px) {
		margin: 3em 5em;
		padding-left: 4em;
		padding-right: 4em;
	}
}

code,
pre {
	font-size: 1em;
	font-family: $font-monospace;
}

.button-link-wrapper {
	margin-top: 2em;
}

.button-link {
	padding: .8em 2em;
	margin: 0;
	display: inline-block;
	min-width: 150px;
	text-align: center;
}

@include link(".button-link") {
	background-color: $color-secondary;
	color: $color-brand;
	text-decoration: none;
}

@include link-over(".button-link") {
	background-color: $color-brand;
	color: $color-secondary;
}